<script setup lang="ts">
import {
  // Directives
  vTooltip,
  vClosePopper,
  // Components
  Dropdown,
  Tooltip,
  Menu,
} from 'floating-vue'
import 'floating-vue/dist/style.css'

import type { IReminder } from '@critical-dates'
type Props = {
  date: Date
  day: string
  remindersByDate: Record<string, IReminder[]>
}

const props = defineProps<Props>()
const dayjs = useDayjs()

// To optimize memory, create an object of reminders by date, and the value is an array of all reminders in that date

const remindersByDateOptimized = computed(() => {
  const dateKey = dayjs(props.date).format('YYYY-MM-DD')
  const reminders = props.remindersByDate[dateKey]
  if (!reminders) return

  return reminders?.map((reminder) => {
    return {
      name: reminder.leaseDetails.name,
      date: reminder.reminderDate,
    }
  })
})

const MAX_ITEMS = 4

const data = computed(() => {
  if (
    remindersByDateOptimized.value &&
    remindersByDateOptimized.value?.length > MAX_ITEMS + 1
  ) {
    return [
      ...remindersByDateOptimized.value.slice(0, MAX_ITEMS),
      {
        name: `+ ${remindersByDateOptimized.value.length - MAX_ITEMS} more`,
        date: props.date,
      },
    ]
  }

  return remindersByDateOptimized.value
})

const reminderColor = (date: string | Date) => {
  return useIsBeforeDate(date, 7, 'day')
    ? 'bg-red'
    : useIsBeforeDate(date, 30, 'day')
      ? 'bg-warning'
      : 'bg-gray-250'
}
</script>

<template>
  <span v-if="data" class="flex h-full w-full">
    <VTooltip
      class="w-full"
      placement="bottom"
      :delay="10"
      :distance="12"
      :auto-hide="true"
    >
      <span class="flex w-full justify-center">
        {{ day }}
      </span>
      <div
        class="absolute bottom-0.5 left-0 right-0 flex h-1 items-center gap-px"
      >
        <div class="flex w-full items-center justify-center gap-px">
          <div
            v-for="alert in data"
            :key="`${alert?.name} ${alert.date}`"
            class="flex h-1 w-1 rounded-lg"
            :class="reminderColor(alert.date)"
          />
        </div>
      </div>

      <template #popper>
        <ul
          class="space-y divide-gray-850 flex list-inside list-disc flex-col divide-y px-2 text-xs text-gray-300 *:py-1.5"
        >
          <li
            v-for="(alert, index) in data"
            :key="alert.name + 'a' + String(index)"
            class="w-full max-w-[500px]"
          >
            {{ alert.name }}
          </li>
        </ul>
      </template>
    </VTooltip>
  </span>
  <template v-else>
    {{ day }}
  </template>
</template>
